<template>
    <div>

        <div class="contenedor-squares js-noticias row-start fww ais">

            <template v-if="loading">

                <div class="square fake" :key="key" v-for="(el,key) in 4">
                    <div class="contenedor-imagen"></div>
                    <div class="contenedor-datos">
                        <h5>
                            <span></span>
                            <span></span>
                        </h5>
                        <a href="">Leer entrevista</a>
                    </div>
                </div>

            </template>

            <entrevista :data="el" :key="key" v-for="(el,key) in data"></entrevista>

        </div>


        <router-link to="/es/comunicacion/entrevistas" class="boton-azul alone">Ver todas</router-link>

    </div>
</template>


<script>

    import { mapActions } from 'vuex'


    import entrevista from '@/components/entrevista-el';

    export default {
        name: 'entrevistas-home',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            loading: false
        }),
        methods: {
            ...mapActions(['requestEntrevistas']),
            setData() {
                this.loading = true;

                let data = {
                    length : 4
                }

                this.requestEntrevistas(data).then(r => {
                    
                    this.data = r.data;
                    this.loading = false;
                })
            }
        },
        components : {
            'entrevista' : entrevista
        }
    }
</script>