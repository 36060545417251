<template>
  <div>
    <div class="contenedor-squares js-noticias row-start fww ais">
      <template v-if="loading">
        <div class="square fake" :key="key" v-for="(el, key) in 4">
          <div class="contenedor-imagen"></div>
          <div class="contenedor-datos">
            <h5>
              <span></span>
              <span></span>
            </h5>
            <a href="">Leer noticia</a>
          </div>
        </div>
      </template>

      <noticia :data="el" :key="key" v-for="(el, key) in data"></noticia>
    </div>

    <router-link class="boton-azul alone" to="/es/comunicacion/noticias/"
      >Ver todas</router-link
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";

import noticia from "@/components/noticia-el";

export default {
  name: "noticias-home",
  mounted() {
    this.setData();
  },
  data: () => ({
    data: "",
    loading: false,
  }),
  methods: {
    ...mapActions(["requestEntries"]),
    setData() {
      this.loading = true;

      let data = {
        length: 4,
        filters: [
          {
            name: "parents",
            search: 0,
          },
        ],
      };

      this.requestEntries(data).then((r) => {
        this.data = r.data;
        this.loading = false;
      });
    },
  },
  components: {
    noticia: noticia,
  },
};
</script>
