<template>

  <div v-if="!!data || !!datapasados">

    <h3 data-to="eventos" v-if="data.length != 0 || datapasados.length != 0">Eventos</h3>


    <h4 class="js-proximos-eventos" v-if="data.length != 0">Próximos eventos</h4>


    <div class="contenedor-eventos js-next-eventos">

      <div class="evento-el" :key="key" v-for="(el,key) in data">
        <router-link :to="'/es/comunidad/eventos/'+el.slug" class="enlace-cover"></router-link>
        <h5>{{el.title}}</h5>
        <p class="fecha">{{el.date | date}}</p>
        <p class="horario">{{el.schedule}}</p>
        <div class="row-between">
          <p class="ubicacion" v-if="el.address">{{el.address}}</p><router-link :to="'/es/comunidad/eventos/'+el.slug">+info e
            inscripciones</router-link>
        </div>
      </div>

    </div>



    <h4 class="js-proximos-eventos" v-if="datapasados.length != 0">Eventos pasados</h4>


    <div class="contenedor-squares js-prev-eventos ais row-start fww">
      <div class="square" :key="key" v-for="(el,key) in datapasados">
        <router-link :to="'/es/comunidad/eventos/'+el.slug" class="enlace-cover"></router-link>
        <div class="contenedor-imagen" :style="`background:#444 url(${el.image.url}) center/cover no-repeat;`">
        </div>
        <div class="contenedor-datos">
          <h5><span>{{el.title}}</span><span>{{el.date | date}}</span></h5>
          <router-link :to="'/es/comunidad/eventos/'+el.slug">Sobre el evento</router-link>
        </div>
      </div>

    </div>


    <router-link id="aVerTodosEventos" to="/es/comunidad/eventos/" class="boton-azul alone">Ver todos</router-link>

  </div>

</template>


<script>

  import { mapActions } from 'vuex';

  export default {
    name: 'eventos',
    mounted() {
      this.setData();
    },
    data: () => ({
      data: '',
      datapasados: ''
    }),
    methods: {
      ...mapActions(['requestEventos', 'requestEventosPasados']),
      setData() {
        this.requestEventos().then(r => {
          this.data = r.data;
        });

        this.requestEventosPasados().then(r => {
          this.datapasados = r.data;
        })
      }
    }
  }
</script>