<template>
    <div class="contenedor-personas js-emprendedores row-start fww">

        <template v-if="loading">
            <div class="contenedor-persona">
                <div class="contenedor-imagen front"></div>
            </div>

            <div class="contenedor-persona">
                <div class="contenedor-imagen front"></div>
            </div>

            <div class="contenedor-persona">
                <div class="contenedor-imagen front"></div>
            </div>

            <div class="contenedor-persona">
                <div class="contenedor-imagen front"></div>
            </div>
        </template>


        <template v-if="data">
            <div class="contenedor-persona emprendedor" v-for="(el,key) in data" :key="key" @click="show(el)">
                <vue-flip active-hover class="contenedor-persona emprendedor" height="180" width="180"
                    v-model="el.flip">
                    <template v-slot:front>
                        <div class="contenedor-imagen">
                            <div class="imagen-persona"
                                :style="`background: url(${el.image.url}) center center / cover no-repeat; backface-visibility: hidden;`">
                            </div>
                        </div>
                    </template>
                    <template v-slot:back>
                        <div class="empresa row-center">
                            <div class="imagen-empresa"
                                :style="`background: url(${el.company_logo.url}) center center / cover no-repeat; backface-visibility: hidden;`">
                            </div>
                        </div>
                    </template>

                </vue-flip>

                <div class="nombre column-center">
                    <h5>{{el.name}}</h5>
                    <p v-if="el.study">{{el.study.name}} {{el.promotion}}</p>
                    <p>{{el.company}}</p>
                </div>



            </div>



        </template>


        <div class="cover-emprededores cover-cerrar" v-if="toshow">
            <div class="contenedor-popup row-center">
                <div class="popup row-between ais">
                    <a class="cerrar-popup material-icons" @click="toshow = ''">✕</a>

                    <div class="info persona">
                        <div class="imagen row-center">
                            <img :src="toshow.image.url" alt="">

                        </div>
                        <div class="titulo">
                            <h5>{{toshow.name}} {{toshow.surnames}}</h5>
                            <span v-if="toshow.study">{{toshow.study.name}} {{toshow.promotion}}</span>
                        </div>
                        <p>{{toshow.description}}</p>
                        <div class="enlaces" v-if="toshow.url">
                            <a target="_blank" :href="toshow.url">Más info</a>
                        </div>

                    </div>

                    <div class="info empresa">
                        <a class="enlace_logo" :href="toshow.company_url" target="_blank">
                            <div class="imagen row-center">
                                <img :src="toshow.company_logo.url" :alt="toshow.company_logo.url">
                            </div>
                        </a>
                        <div>
                            <h5>{{toshow.company}}</h5>
                            <span class="espacio">espacio</span>
                        </div>
                        <div>

                            <p>{{toshow.company_description}}</p>

                            <div class="enlaces" v-if="toshow.company_url">
                                <a target="_blank" :href="toshow.company_url">Más info</a>
                            </div>

                        </div>

                    </div>


                </div>
                <span @click="toshow = ''" class="capa-cerrar-popup"></span>
            </div>
        </div>



    </div>

</template>

<script>

    import { mapActions } from 'vuex'

    import VueFlip from 'vue-flip';


    export default {
        name: 'emprendedores-home',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            loading: false,
            toshow: ''
        }),
        methods: {
            ...mapActions(['requestEmprendedores']),
            setData() {
                this.loading = true;

                let data = {
                    length: 4
                }

                this.requestEmprendedores(data).then(r => {
                    this.data = r.data;
                    this.loading = false;

                    this.data.forEach(e => {
                        e.flip = false;
                    })

                })
            },
            show(el) {
                this.toshow = el;
            }
        },
        components: {
            'vue-flip': VueFlip
        }
    }
</script>